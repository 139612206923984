export default defineComponent({
  data: function data() {
    return {
      newPrimary: "#232F3E",
      // a color that is not in the material colors palette #007d55
      newPrimaryAction: "#FF9900",
      newToolbarText: "#232F3E",
      // a color that is not in the material colors palette #007d55
      newAccent: "#E1E4EA",
      newSecondary: "#828282",
      newChartColor: "#FF9900"
    };
  },
  computed: {
    refinitiv: {
      get: function get() {
        return this.$store.state.config.refinitiv;
      },
      set: function set(value) {
        this.$store.commit("config/setRefinitiv", {
          number: value
        });
      }
    },
    unfinished: {
      get: function get() {
        return this.$store.state.config.unfinished;
      },
      set: function set(value) {
        this.$store.commit("config/setUnfinished", {
          number: value
        });
      }
    },
    // technicalAnalysis: {
    //   get() {
    //     return this.$store.state.config.technicalAnalysis
    //   },
    //   set(value) {
    //     this.$store.commit("config/setTechnicalAnalysis", { number: value })
    //   },
    // },
    forum: {
      get: function get() {
        return this.$store.state.config.forumsActive;
      },
      set: function set(value) {
        this.$store.commit("config/setForum", {
          number: value
        });
      }
    },
    ciqSearch: {
      get: function get() {
        return this.$store.state.config.ciqSearch;
      },
      set: function set(value) {
        this.$store.commit("config/setCiqSearch", {
          number: value
        });
      }
    },
    navBarToShow: {
      get: function get() {
        return this.$store.state.config.navBarToShow;
      },
      set: function set(value) {
        this.$store.commit("config/setNavBarToShow", {
          number: value
        });
      }
    },
    companyNews: {
      get: function get() {
        return this.$store.state.config.companyNews;
      },
      set: function set(value) {
        this.$store.commit("config/setCompanyNews", {
          number: value
        });
      }
    },
    marketNews: {
      get: function get() {
        return this.$store.state.config.marketNews;
      },
      set: function set(value) {
        this.$store.commit("config/setMarketNews", {
          number: value
        });
      }
    },
    darkMode: {
      get: function get() {
        return this.$store.state.config.darkMode;
      },
      set: function set() {
        this.$store.commit("config/setDarkMode", {
          vuetify: this.$vuetify
        });
      }
    },
    denseStockTabs: {
      get: function get() {
        return this.$store.state.config.denseStockTabs;
      },
      set: function set() {
        this.$store.commit("config/setDenseStockTabs");
      }
    },
    autoHidePriceChart: {
      get: function get() {
        return this.$store.state.config.autoHidePriceChart;
      },
      set: function set() {
        this.$store.commit("config/setautoHidePriceChart");
      }
    },
    navColor: {
      get: function get() {
        return this.$store.state.config.navColor;
      },
      set: function set() {
        this.$store.commit("config/setNavColor");
      }
    }
  },
  // computed: {
  //   primary() {
  //     return this.$vuetify.theme.primary
  //   },
  //   primaryAction() {
  //     return this.$vuetify.theme.primaryAction
  //   },
  //   chartColor() {
  //     return this.$store.state.chartColor
  //   },
  //   accent() {
  //     return this.$vuetify.theme.accent
  //   },
  //   secondary() {
  //     return this.$vuetify.theme.secondary
  //   }
  // },
  created: function created() {
    this.newPrimary = this.$vuetify.theme.themes.light.primary;
    this.newPrimaryAction = this.$vuetify.theme.themes.light.primaryAction;
    this.newToolbarText = this.$vuetify.theme.themes.light.toolbarText;
    this.newAccent = this.$vuetify.theme.themes.light.accent;
    this.newSecondary = this.$vuetify.theme.themes.light.secondary;
    this.newChartColor = this.$store.state.chartColor;
  },
  methods: {
    updateColors: function updateColors() {
      this.$vuetify.theme.themes.light.primary = this.newPrimary;
      this.$vuetify.theme.themes.light.primaryAction = this.newPrimaryAction;
      this.$vuetify.theme.themes.light.toolbarText = this.newToolbarText;
      this.$vuetify.theme.themes.light.accent = this.newAccent;
      this.$vuetify.theme.themes.light.secondary = this.newSecondary;
      this.$store.commit("setChartColor", {
        color: this.newChartColor
      });
    },
    resetColors: function resetColors() {
      this.$vuetify.theme.themes.light.primary = "#232F3E";
      this.$vuetify.theme.themes.light.primaryAction = "#FF9900";
      this.$vuetify.theme.themes.light.toolbarText = "#232F3E";
      this.$vuetify.theme.themes.light.accent = "#E1E4EA";
      this.$vuetify.theme.themes.light.secondary = "#828282";
      this.$store.commit("setChartColor", {
        color: "#FF9900"
      });
    }
  }
});